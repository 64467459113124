import * as React from "react";
import Header from "../../Components/Header/Header";
import Customers from "../../Components/Customers/Customers";
import Partnership from "../../Components/Partnership/Partnership";
import Footer from "../../Components/Footer/Footer";
import Seo from "../../Components/Seo/Seo";
import HamburgerMenu from "../../Components/HamburgerMenu/HamburgerMenu";

const ClientiPage = () => {
  return (
    <>
      <Seo locale="en-US" page="they-chose-us" />
      <Header lang="en-US" />
      <HamburgerMenu />
      <Customers lang="en-US" />
      <Partnership lang="en-US" />
      <Footer lang="en-US" />
    </>
  );
};

export default ClientiPage;
