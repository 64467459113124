import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Customers.scss";
import Category from "./Category/Category";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import query from "../Customers/query";
import { Related } from "../CaseHistory/Single/Related";
import CallToAction from "../CallToAction/CallToAction";
import {
  filterDataByLanguage,
  filterListDataByLanguage,
} from "../../Utils/Language";
import PropTypes from "prop-types";

/**
 * Customers Page
 * @returns {JSX.Element}
 * @constructor
 */
const Customers = (props) => {
  const data = query();
  const customersContentData = filterDataByLanguage(
    props.lang,
    data.allContentfulCustomersContent
  );
  const customersCategoryData = filterListDataByLanguage(
    props.lang,
    data.allContentfulCustomersCategory
  );

  const description = JSON.parse(customersContentData.description.raw);

  return (
    <div>
      <div className="customers-container">
        <Container fluid>
          <Row className="customers-main-row">
            <Col lg="6" className="customers-title">
              {customersContentData.title}
            </Col>
            <Col lg="6" className="customers-description">
              {documentToReactComponents(description)}
            </Col>
          </Row>

          <Category customersData={customersCategoryData} />
        </Container>
      </div>
      <div>
        <div className="customers-history">
          <Related title="Case Study" item={customersContentData.relatedCase} />
          <Container fluid>
            <Row>
              <Col className="col-lg-6 mb-5 link link-bright link-arrow-animation">
                <CallToAction
                  label={customersContentData.caseStudy.key}
                  to={customersContentData.caseStudy.value}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

Customers.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Customers;
