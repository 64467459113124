import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query {
      allContentfulCustomersCategory(sort: { fields: contentful_id }) {
        nodes {
          node_locale
          id
          categoryTitle
          node_locale
          logos {
            gatsbyImageData
            file {
              fileName
              url
            }
            id
          }
        }
      }
      allContentfulCustomersContent {
        nodes {
          node_locale
          title
          description {
            raw
          }
          relatedCase {
            customerName
            customerLink
            customerImage {
              gatsbyImageData
              file {
                url
                fileName
              }
            }
            customerDescription {
              customerDescription
            }
          }
          caseStudy {
            key
            value
          }
        }
      }
    }
  `);
};

export default query;
