import * as React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import "./Category.scss";
import "../../../Global.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

/**
 * Renders a list of customers by categories (Fashion Industry, Sports, etc...)
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Category = (props) => {
  return (
    <div key="customers-category">
      {props.customersData.map((el) => (
        <div key={el.id}>
          <Col className="mt-5 customers-list-title p-0 text-start">
            {el.categoryTitle}
          </Col>
          <Row className="align-items-center mt-3 mt-lg-5 pb-5 customers-logo text-center">
            {el.logos
              ? el.logos.map((item, i) => (
                  <>
                    {i % 4 === 0 ? (
                      <Col
                        key={item.id}
                        className="customers-category-item mt-3 mb-5 col-6 col-lg-2"
                      >
                        <GatsbyImage
                          className="img-fluid"
                          image={getImage(item)}
                          alt={item.file.name}
                        />
                      </Col>
                    ) : (
                      <Col
                        key={item.id}
                        className="customers-category-item mt-3 mb-5 col-6 col-lg-2 offset-lg-1"
                      >
                        <GatsbyImage
                          className="img-fluid"
                          image={getImage(item)}
                          alt={item.file.name}
                        />
                      </Col>
                    )}
                  </>
                ))
              : null}
          </Row>
        </div>
      ))}
    </div>
  );
};

Category.propTypes = {
  customersData: PropTypes.object,
  desktopImage: PropTypes.string,
  mobileImage: PropTypes.string,
  alt: PropTypes.string,
};

export default Category;
